<template>
  <div style="margin-bottom: 16px;">
    <div :class="['row', showObj.type == 'icon' && 'line-center']" :style="type != 'list' && 'display: flex'">
      <div :class="['left-box', showObj.isChange ? 'isChange' : 'noChange']" :style="showObj.labelStyle || {}">
        {{ showObj.label }}
      </div>
      <!-- 文字 -->
      <div v-if="type == 'text'" class="right-box">
        <a-tooltip overlayClassName="seal-trustee-overlay">
          <template #title
            >{{ showObj.value || '--' }} <span v-if="showObj.label === '用印超时预警时间'">小时</span></template
          >
          {{ showObj.value || '--' }} <span v-if="showObj.label === '用印超时预警时间'">小时</span>
        </a-tooltip>
      </div>
      <!-- 图标 -->
      <img v-if="type == 'icon'" class="icon" :src="showObj.value" alt />
      <!-- 图片 -->
      <div v-if="type == 'image'">
        <section
          v-viewer="printImgOptions"
          :images="showObj.value"
          class="img-collection"
          style="display:flex;flex-wrap:wrap"
          v-if="showObj.value && showObj.value.length"
        >
          <div class="stamped-image" v-for="(item, index) in showObj.value" :key="item">
            <div class="seal-imgs" style="margin-right:0;margin-bottom: 6px;position:relative">
              <img
                :src="item + '?uploadFile=104x104'"
                @click="previewImg(item, index, 'printImg')"
                alt
                @error="reImg($event, item, 'print')"
                :data-imageType="item.imageType"
                style="width: 60px;height:60px;cursor: pointer;"
                class="seal-img"
              />
            </div>
          </div>
        </section>
        <span v-else class="default">无</span>
      </div>

      <!-- 表格 -->
      <div v-if="type == 'list'" style="margin-top:8px;">
        <a-table
          :columns="showObj.value.columns"
          :data-source="showObj.value.data"
          :pagination="showObj.value.pagination"
          :scroll="{ x: true }"
        >
          <!-- todo:两个都需要 -->
          <template v-for="item in showObj.value.customKeys" :key="item.key" #[item.key]="{text}">
            <div v-if="item.customText ? hasExceedLen(item, item.customText(text).newText) : hasExceedLen(item, text)">
              {{ item.customText ? item.customText(text).newText : text || '-' }}
            </div>
            <a-tooltip placement="bottomLeft" v-else overlayClassName="seal-trustee-overlay">
              <template #title>
                <span>{{ item.customText ? item.customText(text).allText : text || '-' }}</span>
              </template>
              <div class="lang" :style="{ 'max-width': item.maxWidth ? item.maxWidth + 'px' : 'auto' }">
                {{ item.customText ? item.customText(text).newText : text || '-' }}
              </div>
            </a-tooltip>
          </template>
        </a-table>
      </div>

      <!-- 权限授权 -->
      <div v-if="type == 'authority'" style="margin-top: -7px;">
        <a-tree
          :checkedKeys="showObj.value.power"
          checkable
          disabled
          v-model:expanded-keys="expandedKeys"
          :auto-expand-parent="autoExpandParent"
          :tree-data="showObj.value.data"
          :replaceFields="{ children: 'child', title: 'name', key: 'id' }"
          class="form-item-width show-scrollbar te-power"
          style="max-width: 540px;min-width: 220px;"
        >
          <template #switcherIcon>
            <LeftOutlined />
          </template>
        </a-tree>
      </div>

      <!-- 多行文字 -->
      <div v-if="type == 'textList'" class="right-box">
        <div v-for="item in showObj.value" :key="item">{{ item }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import { LeftOutlined } from '@ant-design/icons-vue'

export default {
  props: {
    showObj: {
      type: Object
    },
    type: {
      type: String,
      default: 'text'
    }
  },
  components: {
    LeftOutlined
  },
  setup(props) {
    const state = reactive({
      imgList: [],
      showImgList: [],
      expandedKeys: [],
      permissionList: [],
      power: [2], // 选中的id
      autoExpandParent: false // 是否展开
    })

    const vcon = val => {
      console.log(val)
    }

    // 查看图片
    const previewImg = (src, i, type) => {
      console.log('9999999', src)
      if (!src) return

      state.showImgList = []
      console.log('用印影像imageList的值', state.showImgList)

      // photoIndex.value = current.value.imgList.indexOf(src)
      // previewVisible.value = true
    }

    // 查看图片配置
    const printImgOptions = {
      navbar: false,
      title: false,
      transition: false
    }

    const hasExceedLen = (item, text) => {
      console.log(typeof text != 'string', !text, item.len)

      if (typeof text != 'string' || !text || !item.len) {
        return true
      }

      return text?.length <= item.len
    }
    onMounted(() => {
      console.log('props', props)
    })

    return {
      previewImg,
      printImgOptions,
      ...toRefs(state),
      hasExceedLen,
      vcon
    }
  }
}
</script>

<style lang="less" scoped>
.row {
  // display: flex;
  .left-box {
    width: 180px;
    text-align: right;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }

  .right-box {
    // width: calc(100% - 180px);
    flex: 1;
    word-break: break-all;
    word-wrap: break-word;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: 22px;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    :deep(.ant-tooltip-open) {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }

    // .seal-img, .stamped-image {
    //   width: 60px;
    //   height: 60px;
    // }
  }
}

.isChange {
  color: #0A7BFF;
}

.noChange {
  color: rgba(0, 0, 0, 0.85);
}

.icon {
  width: 60px;
  height: 60px;
}

.line-center {
  align-items: center;
}

.img-collection {
  .seal-name {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 26px;
    background: rgba(26, 26, 27, 0.6);
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 22px;
    padding: 2px 10px;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
  }
}

.text-ellipsis {
  width: 112px;
  overflow: hidden;
  white-space: nowrap;

  text-overflow: ellipsis;
}

:deep(.ant-table-thead) {
  tr > th:nth-child(1) {
    padding-left: 24px;
  }
}

:deep(.ant-table-tbody) {
  tr > td:nth-child(1) {
    padding-left: 24px;
  }
}

.te-power {
  // max-height: 300px;
  // overflow-y: scroll;
  :deep(.ant-checkbox-wrapper) {
    display: block;
    margin-left: 0;
  }
  :deep(.ant-tree-switcher) {
    left: 130px;
    transition: 0.3s;
  }
  :deep(.ant-tree-switcher_open) {
    transform: rotateZ(90deg);
  }
  :deep(li span.ant-tree-switcher, .ant-tree li span.ant-tree-iconEle) {
    width: 12px;
  }
}

.default {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}

.lang {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

// .tooltip-custom {
//   .ant-tooltip-inner {
//     // 这里是框框
//     color: #323233;
//     background-color: #fff !important;
//     border-radius: 2px;
//   }

//   .ant-tooltip-arrow::before {
//     // 这里是小三角形
//     background-color: #fff !important;
//   }
// }
</style>
